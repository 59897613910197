// eslint-disable-next-line no-unused-vars
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyD_8FwVQReG3Xo8s6OC3g_TN6eHee1WJqE",
	authDomain: "tnsu-exam.firebaseapp.com",
	databaseURL: "https://tnsu-exam-default-rtdb.asia-southeast1.firebasedatabase.app",
	projectId: "tnsu-exam",
	storageBucket: "cdn.research-exam.com",
	messagingSenderId: "954122938832",
	appId: "1:954122938832:web:01685d3b14b4c80373f1d5",
	measurementId: "G-S6JT39YP4T",
};

initializeApp(firebaseConfig);
// connectFunctionsEmulator(getFunctions(), "localhost", 5001);

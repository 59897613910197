<template>
    <div class="layout-footer">
        <div class="footer-logo-container">
            <img id="footer-logo" src="@/assets/logo.png" alt="TNSU" />
            <span class="app-name">TNSU</span>
        </div>
        <span class="copyright">&#169; TNSU (<small>V.{{version}}</small>) - {{ new Date().getFullYear() }}</span>
    </div>
</template>

<script>
import { version } from "../package.json"
export default {
    setup () {
        return { version }
    },
    name: "AppFooter"
};
</script>

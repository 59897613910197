import {defineStore, mapState} from 'pinia';
import {MemberStore} from "@/uhas/store/member";
import {
	getAuth,
	onAuthStateChanged,
	signInWithPhoneNumber,
	signInWithCustomToken,
	RecaptchaVerifier,
	signOut
} from "firebase/auth";
import {
	getFunctions,
	httpsCallable
} from "firebase/functions";

const functions = getFunctions();
const auth = getAuth();

export const AuthStore = defineStore('auth', {

	// persist: {
	// 	paths: ['logged']
	// },

	state: () => ({
		logged: false
  }),

	getters: {
		...mapState(MemberStore, ['user', 'avatar', 'coverImage'])
	},

	actions: {
		signIn (phone, elmID) {
			return new Promise((resolve, reject) => {
				let recaptchaVerifier = new RecaptchaVerifier(elmID, {
					size: 'invisible',
					callback: () => {
						// reCAPTCHA solved, allow signInWithPhoneNumber.
						signInWithPhoneNumber(auth, phone, recaptchaVerifier)
							.then((confirmation) => {
								// SMS sent. Prompt user to type the code from the message, then sign the
								resolve(confirmation.verificationId);
							}).catch(error => {
								reject(error);
							})
					},
					'expired-callback': () => {
						reject({ message: 'Timeout' });
					}
				}, auth);

				recaptchaVerifier.verify();
			})
		},

		submitOTP (otp, verificationId) {
			return new Promise((resolve, reject) => {
				httpsCallable(functions, "auth-loginWithPhone")({ otp, verificationId }).then(({ data }) => {
					if (data.error) {
						reject(data);
					} else {
						signInWithCustomToken(auth, data.user.token).then(userCredential => {
							const memberStore = MemberStore();
							memberStore.user = {
								...data.user,
								uid: userCredential.user.uid
							};
							this.logged = true;
							resolve(data);
						}).catch(err => reject(err))
					}
				}).catch(err => reject(err))
			})
		},

		getCurrentUser () {
			return new Promise((resolve, reject) => {
				const unsubscribe = onAuthStateChanged(getAuth(), user => {
					unsubscribe();
					if (user) {
						const memberStore = MemberStore();
						if (!memberStore.user) {
							this.signOut();
							this.logged = false;

							reject(null);
						} else {
							this.logged = true;
						}
					} else {
						this.logged = false;
					}

					resolve(user);
				}, () => {
					this.logged = false;
					reject(null)
				})
			})
		},

		signOut () {
			return new Promise((resolve) => {
				signOut(auth).then(() => {
					const memberStore = MemberStore();
					memberStore.user = null;
					this.logged = false;
					resolve();
				});
			})
		}
	}
})

<script setup>
import { ref, computed } from "@vue/reactivity"
import { useRouter } from "vue-router"
import {useToast} from "primevue/usetoast";
import { AuthStore } from "@/uhas/store/auth"

import { version } from "../../../package.json"

const toast = useToast();
const authStore = AuthStore()
const router = useRouter()
const phone = ref("") // 0800000001
const otp = ref("") // 123456
const verificationId = ref("")
// const errMsg = ref("")
const loading = ref(false)
const otpRequested = ref(false)

const submitFn = computed(() => {
  return otpRequested.value ? submitOTP : signIn
})

const signIn = () => {
  let p = phone.value
  if (p.substring(0, 1) === "0") {
    p = "+66" + p.substring(1)
  }
  loading.value = true
  authStore.signIn(p, 'phone').then(vId => {
    verificationId.value = vId
    otpRequested.value = true
  }).catch(err => {
    console.log(err)
  }).finally(() => {
    loading.value = false
  })
}

const submitText = computed(() => {
	if (otpRequested.value) {
		if (loading.value) {
			return "กำลังตรวจสอบ..."
		} else {
			return "ตรวจสอบ"
		}
	} else {
		if (loading.value) {
			return "กำลังส่งรหัส OTP..."
		} else {
			return "ดำเนินการต่อ"
		}
	}
})
const submitOTP = () => {
  loading.value = true
  authStore.submitOTP(otp.value, verificationId.value).then(() => {
    router.push("/dashboard")
  }).catch(err => {
		let msg = null;
		if (err.error?.code === "auth/invalid-verification-code") {
			msg = "OTP ไม่ถูกต้อง";
		}	else if (err.error?.code === "auth/code-expired") {
			msg = "OTP หมดอายุ กรุณา refresh แล้วขอ OTP ใหม่อีกครั้ง";
		} else if (err.error?.code) {
			msg = "Code: " + err.error?.code;
		} else {
			msg = "มีบางอย่างผิดพลาด กรุณาติดต่อผู้ดูแลระบบ";
		}

		toast.add({
			severity:"error",
			summary: "ผิดพลาด",
			detail: msg,
			life: 10000
		});
    console.log(err.error || err)
  }).finally(() => {
    loading.value = false
  })
}
</script>

<style scoped lang="scss">
.p-float-label {
	width: 85%;
	max-width: 310px;
	margin-bottom: 30px;

	input {
		width: 100%;
	}
}
.login-body .login-wrapper .login-panel {
	.login-form {
		min-width: 300px;
	}
	.logo {
		height: 150px;
	}
}
</style>

<template>
	<Toast />

	<div class="login-body">
		<div class="login-wrapper">
			<div class="login-panel">
				<img src="@/assets/logo.png" class="logo" alt="UHAS" />

				<div class="login-form">
					<h2 class="mb-6">เข้าสู่ระบบ</h2>
					<span class="p-float-label">
						<InputMask
							v-model="phone"
							id="phone"
							mask="999 999 9999"
							slotChar=""
						/>
						<label for="phone">เบอร์โทรศัพท์</label>
					</span>
					<span
						v-if="otpRequested"
						class="p-float-label"
					>
						<InputMask
							v-model="otp"
							id="otp"
							mask="999999"
							slotChar=""
						/>
						<label for="otp">รหัส OTP</label>
					</span>
					<Button
						class="mb-1"
            :loading="loading"
						:label="submitText"
            @click="submitFn"
					/>
					<small>V.{{ version }}</small>
				</div>

				<p>
					<!-- Back to <router-link to="/">home</router-link> page. -->
					<a href="/manual.pdf" target="_blank">คู่มือการใช้งาน</a>
				</p>
			</div>
			<div class="login-image">
				<div class="login-image-content">
					<h1 class="mt-6">การวัดสมรรถนะวิชาชีพครู</h1>
					<!-- <h1>Diamond</h1>
					<h1>Account</h1> -->
					<h3>
						สาระความรู้การวิจัยเพื่อแก้ปัญหาและพัฒนาผู้เรียน ของนักศีกษามหาวิทยาลัยการกีฬาแห่งชาติ
					</h3>
				</div>
				<!-- <div class="image-footer">
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					<div class="icons">
						<i class="pi pi-github"></i>
						<i class="pi pi-twitter"></i>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

import { version } from '../../../package.json'
import { createPinia } from 'pinia'
import {createPersistedState} from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use((context) => {
	let options = {}
	if (context.options.persist) {
		options.key = `tnsu-exam.${context.store.$id}-${version.slice(0, version.length - 2)}.x`
	}
	createPersistedState(options)(context)
})

export default pinia

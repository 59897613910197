import {defineStore} from 'pinia';
import {getFunctions, httpsCallable} from "firebase/functions";
import {getStorage, ref, uploadBytes, } from "firebase/storage";
import {v4 as uuidv4} from "uuid";

const functions = getFunctions();
const storage = getStorage();

export const MemberStore = defineStore('member', {

	persist: {
		paths: ["user", "examination"]
	},

  state: () => ({
		user: null,
		examination: null,
		assessment: {
			a: [0, 0, 0, 0, 0, 0, 0, 0, 0],
			q: [
				"ระบบมีการทำงานที่สะดวก ง่าย ต่อการใช้งาน และไม่ซับซ้อน",
				"ระบบมีการระบุข้อปฏิบัติการใช้งานไว้อย่างชัดเจน",
				"ผู้ใช้งานสามารถเรียนรู้การใช้งานและการทำงานผ่านคู่มือการใช้ระบบด้วยตนเองได้",
				"ระบบมีความรวดเร็วในการเข้าถึง",
				"รูปแบบของข้อความ สี และส่วนประกอบต่างๆ บนหน้าจอมีความเหมาะสม",
				"ลำดับการแสดงภาพและส่วนประกอบต่างๆ บนหน้าจอมีความเหมาะสม",
				"ความรวดเร็วในการการรายงานผล การทดสอบและให้ข้อมูลย้อนกลับ",
				"การรายงานผลการทดสอบและให้ข้อมูลย้อนกลับมีความชัดเจน เข้าใจง่าย",
				"การรายงานผลการทดสอบและการให้ข้อมูลย้อนกลับนำไปใช้ในการเตรียม ความพร้อมก่อนการทดสอบสมรรถนะวิชาชีพครูได้",
			]
		}
	}),

	getters: {
		fullName: (state) => `${state.user?.firstName || ''} ${state.user?.lastName || ''}`,
		avatar: (state) => `https://cdn.research-exam.com/${state.user?.avatar || "no-img.png"}`,
		exam: state => {
			if (state.examination?.e < new Date().getTime()) {
				state.examination = null;
			}

			return state.examination;
		},
		examResults: state => state.user?.examResults,
	},

	actions: {
		saveBasicInfo (info) {
			return new Promise((resolve, reject) => {
				const {user, newAvatarFile} = info;
				let uuid = null, ext = null, path = null, uploadTasks = [];
				if (newAvatarFile) {
					uuid = uuidv4();
					ext = newAvatarFile.type === "image/png" ? ".png" : ".jpg";
					path = `members/${user.uid}/avatar/${uuid.substring(0, 12) + ext}`;
					uploadTasks.push(uploadBytes(ref(storage, path), newAvatarFile));

					user.newAvatar = path
				}

				Promise.all(uploadTasks).then(() => {
					httpsCallable(functions, "member-updateBasicInfo")({user}).then(({data}) => {
						if (data.error) {
							reject(data);
						} else {
							resolve(data);
						}
					}).catch(err => reject(err))
				})
			})
		},

		examTimeout () {
			this.examination = null;
		},

		startExam () {
			return new Promise((resolve, reject) => {
				httpsCallable(functions, "member-startExam")().then(({data}) => {
					if (data.error) {
						reject(data);
					} else {
						this.examination = data;
						this.examination.a = data.q.map(() => -1);
						resolve(data);
					}
				}).catch(err => reject(err))
			})
		},

		submitExam () {
			return new Promise((resolve, reject) => {
				httpsCallable(functions, "member-submitExam")({
					answer: this.examination.a,
					set: this.examination.i
				}).then(({data}) => {
					if (data.error) {
						reject(data);
					} else {
						this.user.examResults.push({
							s: this.examination.s,
							e: this.examination.e,
							score: data
						});
						this.examination = null;

						resolve(data);
					}
				}).catch(err => reject(err))
			})
		},

		submitedAssement () {
			return new Promise((resolve, reject) => {
				httpsCallable(functions, "member-submitedAssement")({
					answer: this.assessment.a,
				}).then(({data}) => {
					if (data.error) {
						reject(data);
					} else {
						this.user.assessment = true;
						resolve(data);
					}
				}).catch(err => reject(err))
			})
		}
	}
})

import {createRouter, createWebHistory} from 'vue-router';
import {AuthStore} from "@/uhas/store/auth";

const routes = [
    {
        path: '/dashboard',
				alias: ['/'],
        name: 'dashboard',
        exact: true,
        component: () => import('@/uhas/pages/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Dashboard', label: 'แดชบอร์ด' }],
						requiresAuth: true,
        },
    },
		{
			path: '/profile',
			name: 'profile',
			exact: true,
			component: () => import('@/uhas/pages/Profile.vue'),
			meta: {
					breadcrumb: [{ label: 'โปรไฟล์' }],
					requiresAuth: true,
			},
		},
		{
			path: '/exam',
			name: 'exam',
			exact: true,
			component: () => import('@/uhas/pages/Exam.vue'),
			meta: {
					breadcrumb: [{ label: 'ทำข้อสอบ และประเมินการใช้งาน' }],
					requiresAuth: true,
			},
		},
		{
			path: '/manual',
			name: 'manual',
			exact: true,
			component: () => import('@/uhas/pages/Manual.vue'),
			meta: {
					breadcrumb: [{ label: 'คู่มือการใช้งาน' }],
					requiresAuth: true,
			},
		},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior () {
			return { left: 0, top: 0 };
	}
});

router.beforeEach(async (to, from, next) => {
	const authStore = AuthStore();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await authStore.getCurrentUser()) {
			next();
    } else {
      next("/login");
    }
  } else if (to.path === '/login' && await authStore.getCurrentUser()) {
		next("/dashboard");
	} else {
    next();
  }
});

export default router;
